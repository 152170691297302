import React from 'react';
import Layout from '../components/layout';
import Gyik from '../components/egy-szazalek/gyik';
import How from '../components/egy-szazalek/how';
import GameHero from '../components/egy-szazalek/game-hero';

const TamogatasPage = () => {
  return (
    <Layout
      seo={{
        title: '1% felajánlás',
        description: 'A Fortély-csapat'
      }}
    >
      <GameHero />
      <How />
      <Gyik />
    </Layout>
  );
};

export default TamogatasPage;
