import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';

const GameHero = () => {
  const images = useStaticQuery(graphql`
    query {
      girl: file(relativePath: { regex: "/egy/egy_hero.png/" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1063) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `);
  return (
    <div className="bg-bgfirst">
      <div className="container py-20">
        <h2 className="uppercase text-center my-10 max-w-lg mx-auto">
          A Te 1<span className="percentage">%</span>-od kell, hogy másoknak is
          elindulhasson a játék!
        </h2>
        <div className="mx-auto">
          <Img
            className="mx-auto my-auto"
            fluid={images.girl.childImageSharp.fluid}
            alt="Play"
          />
        </div>
      </div>
    </div>
  );
};

export default GameHero;
