import React from 'react';
import Accordion from './accordion';

const Gyik = () => {
  return (
    <div className="container my-20">
      <h2 className="uppercase text-center mb-16">Gyakran ismételt kérdések</h2>
      <div className="max-w-lg xl:ml-24">
        <Accordion title="Mi a határidő, amíg rendelkezhetek az adóm 1%-áról?">
          {`Idei évben ${new Date().getFullYear()}. május 20. a határidő, de
          nem érdemes az utolsó napokra hagyni.`}
        </Accordion>
        <Accordion title="Mekkora összegről tudok rendelkezni?">
          Ez mindenkinél eltérő, tavalyi évben az átlagos felajánlás 5 900 Ft
          volt. Amennyiben nem rendelkezel semmilyen adókedvezménnyel, akkor az
          éves bruttó béredet szorozd meg 0,0015-del.
        </Accordion>
        <Accordion title="Nagyon kevés jött ki, felajánjam?">
          Mindenképp, egy szervezet életében nincs kis támogatás, csak fel nem
          ajánlott.
        </Accordion>
        <Accordion title="Honnan tudom, hogy jó helyre megy az összeg?">
          Ha a felajánlás során hozzájárulsz, hogy kapcsolatba léphessünk veled,
          akkor el fogjuk neked küldeni a beszámolónkat, illetve rendszeresen
          hírt adunk majd a tevékenységünkről.
        </Accordion>
        <Accordion title="Elakadtam, tudtok segíteni? ">
          Persze, írj nekünk a{' '}
          <a
            href="mailto:hello@fortelysator.hu"
            className="text-primary hover:underline"
          >
            hello@fortelysator.hu
          </a>{' '}
          címre és segítünk.
        </Accordion>
      </div>
    </div>
  );
};

export default Gyik;
