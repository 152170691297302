import React from 'react';

const HowCard = ({ img, description, width, link, linkUrl }) => {
  return (
    <div className="howCard bg-white shadow-xl xl:m-5 rounded-lg h-64 w-40 mt-5 ml-2 mr-2 mx-auto">
      <img
        alt={img}
        src={`/icons/${img}.svg`}
        width={width}
        className="mx-auto mt-2"
      />
      <p className="text-center w-32 pt-3 mx-auto text-base font-semibold">
        {description}{' '}
        <a
          href={linkUrl} // eslint-disable-next-line react/jsx-no-target-blank
          target="_blank"
          className="text-primary hover:underline"
        >
          {link}
        </a>
      </p>
    </div>
  );
};

export default HowCard;
