import React from 'react';

function Add() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      fill="none"
      viewBox="0 0 36 36"
    >
      <path
        fill="#ED8936"
        d="M18.02 7.016L18.25 7a1.687 1.687 0 011.672 1.458l.015.23v7.874h7.875a1.687 1.687 0 011.672 1.458l.016.23a1.687 1.687 0 01-1.458 1.672l-.23.015h-7.875v7.875a1.687 1.687 0 01-1.457 1.672l-.23.016a1.687 1.687 0 01-1.672-1.458l-.015-.23v-7.875H8.688a1.688 1.688 0 01-1.672-1.457L7 18.25a1.687 1.687 0 011.458-1.672l.23-.015h7.874V8.688a1.688 1.688 0 011.458-1.672L18.25 7l-.23.016z"
      />
    </svg>
  );
}

export default Add;
