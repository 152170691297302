import React, { useState } from 'react';
import Sub from '../../../static/icons/sub';
import Add from '../../../static/icons/add';

const Accordion = ({ children, title, buttonId }) => {
  const [isOpen, setIsopen] = useState(false);

  return (
    <div className="border-b border-textDark m-5 pb-4">
      <div
        tabIndex="0"
        role="button"
        onKeyDown={e => {
          if (e.key === 13) {
            setIsopen(!isOpen);
          }
        }}
        onClick={() => setIsopen(!isOpen)}
        className="justify-between flex cursor-pointer outline-none"
        id={buttonId}
      >
        <h4 className="py-5 text-lg">{title}</h4>
        <div className="flex items-center">{isOpen ? <Sub /> : <Add />}</div>
      </div>
      <div className="">
        {isOpen ? (
          <p className="opacity-60 pt-2 text-textNormal">{children}</p>
        ) : null}
      </div>
    </div>
  );
};

export default Accordion;
