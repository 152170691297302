import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';
import HowCard from './how-card';
import data from './steps-data';

const How = () => {
  const images = useStaticQuery(graphql`
    query {
      girl: file(relativePath: { regex: "/egy/egy_about.jpg/" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 414) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `);
  return (
    <div className="container my-20">
      <h2 className="uppercase text-center my-10">Hogyan tudod Felajánlani?</h2>
      <h4 className="text-2xl italic font-medium mb-10 mt-20 ml-3 xl:ml-24">
        Online adóbevallás esetén
      </h4>
      <div className="flex flex-wrap justify-center md:px-20 lg:px-0 mx-auto">
        {data.map(step => (
          <HowCard
            img={step.img}
            description={step.description}
            width={step.width}
            link={step.link}
            linkUrl={step.linkUrl}
          />
        ))}
      </div>
      <div className="px-4 py-8 lg:py-16 md:flex md:flex-row-reverse md:justify-between mt-10">
        <div className="md:w-1/2 mb-10">
          <Img
            className=" mx-auto my-auto"
            fluid={images.girl.childImageSharp.fluid}
            alt="Play"
          />
        </div>
        <div className="md:w-1/2 max-w-md xl:ml-24">
          <h4 className="text-2xl italic font-medium">
            Felajánlás papír alapon
          </h4>
          <p className="pt-6">
            Az 1+1%-os rendelkező nyilatkozat postai úton is benyújtható. Ehhez
            a 21SZJA bevallás részeként a 21SZJA-EGYSZA lapjának a kitöltésével
            tudsz rendelkezni. A nyomtatványon a Fortély-sátor Alapítvány
            adószámát kell feltüntetni: 18989920-2-43. A nyomtatvány{' '}
            <a
              href="https://nav.gov.hu/nyomtatvanyok/letoltesek/nyomtatvanykitolto_programok/nyomtatvanykitolto_programok_nav/21szja"
              className="text-primary hover:underline"
              // eslint-disable-next-line react/jsx-no-target-blank
              target="_blank"
            >
              ide kattintva
            </a>{' '}
            érhető el.
          </p>
          <h4 className="text-2xl italic font-medium mt-20">
            Könyvelő készíti
          </h4>
          <p className="pt-6">Küldd el neki ezeket az adatokat:</p>
          <p className="">
            <span className="font-bold">Szervezet neve:</span> Fortély-sátor
            Alapítvány
          </p>
          <p className="">
            <span className="font-bold">Adószám:</span> 18989920-2-43
          </p>
        </div>
      </div>
    </div>
  );
};

export default How;
