export default [
  {
    img: 'infiniti',
    width: '72',
    description: 'Lépj be az e-SZJA oldalra',
    link: 'itt',
    linkUrl: 'https://eszja.nav.gov.hu/app/login/'
  },
  {
    img: 'left-arrow',
    width: '72',
    description: 'Jobb oldalon találod az 1+1% felajánlási lehetőséget'
  },
  {
    img: 'search',
    width: '72',
    description: 'Keress rá a Fortély-sátor Alapítványra'
  },
  {
    img: 'column',
    width: '72',
    description: 'Kattints az ellenőrzés gombra'
  },
  {
    img: 'dones',
    width: '72',
    description: 'Nézd át az adatokat'
  },
  {
    img: 'done',
    width: '72',
    description: 'Tovább a beadáshoz és végül beadás'
  }
];
