import React from 'react';

function Sub() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      fill="none"
      viewBox="0 0 36 36"
    >
      <path
        fill="#ED8936"
        d="M6.75 18a1.687 1.687 0 011.688-1.688h19.124a1.687 1.687 0 110 3.375H8.438A1.687 1.687 0 016.75 18z"
      />
    </svg>
  );
}

export default Sub;
